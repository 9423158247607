<template>
  <header class="layout-topbar" :class="$store.state.toggleMenu ? 'active' : ''">
    <div class="flex items-center con-layout-topbar">
      <Button v-html="$icon.svg.uploadOutlined" class="btn-upload-excel p-button-secondary md:hidden hidden lg:block" @click="showingModal()" />
      <Button v-html="$icon.svg.crossAkarIcons" class="btn-event-panel p-button-secondary p-button-outlined" v-if="$store.state.toggleMenu" @click="emitToggle(false)"/>
      <Button v-html="$icon.svg.menuOutlineEva" @click="emitToggle(true)" class="btn-event-panel p-button-secondary p-button-outlined" v-else/>
      <div class="content-header flex items-center justify-center pl-2 lg:pl-0 md:pl-0 md:justify-between lg:justify-between flex-auto">
        <h6 class="text-center lg:text-left md:text-left">{{ ($route.meta) ? $route.meta.group : '' }}</h6>
        <div class="icon-header items-center hidden lg:flex md:flex">
          <div class="search-icon mr-4 cursor-pointer" v-html="$icon.svg.searchEva"></div>
          <div class="bell-icon cursor-pointer" v-html="$icon.svg.bellFeather"></div>
        </div>
      </div>
      <div class="lg:right-header md:right-header flex items-center">
        <div class="biodata mr-3 text-right lg:block md:block hidden">
          <p class="name-profile">{{ $store.getters['appActiveUser/getAppActiveUser']?.fullname }}</p>
          <p class="role-profile color-blue-grey text-sm">{{ $store.getters['appActiveUser/getAppActiveUser']?.role }}</p>
        </div>
        <ProfileDropdown />
      </div>
    </div>
    <ModalUploadExcel :isShow="isShowModalUploadExcel" @hideDialog="hideDialogDetail" />
  </header>
</template>

<script>
import Button from 'primevue/button'
import ProfileDropdown from '@/views/layouts/navigation/ProfileDropdown.vue'
import {
  useStore
} from 'vuex'
import ModalUploadExcel from '@/views/shared-components/ModalUploadExcel.vue'
import {
  ref,
  onMounted
} from 'vue'

export default {
  name: 'HeaderPanel',
  components: {
    Button,
    ProfileDropdown,
    ModalUploadExcel
  },
  setup() {
    const store = useStore()
    const isShowModalUploadExcel = ref(false)
    const emitToggle = (val) => {
      store.dispatch('setToggleMenu', val)
    }
    const hideDialogDetail = () => {
      isShowModalUploadExcel.value = false
    }
    const showingModal = () => {
      isShowModalUploadExcel.value = true
    }
    onMounted(() => {
      store.dispatch('appActiveUser/setProfile')
    })
    return {
      emitToggle,
      isShowModalUploadExcel,
      hideDialogDetail,
      showingModal
    }
  }
}
</script>
